import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';

import S from './page-calendar.module.scss';
import { Calendar } from './calendar/calendar';
import { useBusiness } from '../../../../graphql/graph-hooks';
import * as TimeHelpers from '../../../../helpers/times';

const PageCalendar = ({ history, location }) => {
  const { timezone, id } = useBusiness();
  const { i18n } = useTranslation();

  posthog.capture('BookingCalendarPage', { businessId: id });

  const DATE = useMemo(() => {
    if (location.search) {
      const MOMENT = moment.tz(
        new URLSearchParams(document.location.search).get('date'),
        TimeHelpers.TimePatterns.date,
        timezone
      );
      if (moment.isMoment(MOMENT)) {
        return MOMENT.toISOString();
      }
    }
    return TimeHelpers.getCurrentTimeAtTimezone(timezone).toISOString();
  }, [location.search, timezone]);

  const onRedirect = useCallback((path) => history.push(path), [history]);

  return (
    <div className={S.pageWrapper}>
      <Calendar
        onRedirect={onRedirect}
        date={DATE}
        calendarType={i18n.language === 'en' ? 'US' : 'ISO 8601'}
        locale={i18n.language}
      />
    </div>
  );
};

export { PageCalendar };
