import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';

import { Tabs as CommonTabs } from 'components/common/tabs';

import { BOOKING_STATUS } from 'helpers/enums';
import type { TabsData } from './types';

const { ACCEPTED, CHECKED_IN, CHECKED_OUT, PENDING, INVITED, REJECTED, STANDBY } = BOOKING_STATUS;

interface Props {
  activeTab: BOOKING_STATUS;
  onChange: (value: BOOKING_STATUS) => void;
  counts: { [key: string]: number };
  bookingId: string | undefined;
}

const Tabs: FC<Props> = ({ activeTab, onChange, counts, bookingId }) => {
  const { t } = useTranslation();
  const tabs: TabsData = [PENDING, INVITED, ACCEPTED, REJECTED, STANDBY].map((item) => {
    const status = item.toLowerCase();
    const count =
      bookingId && [ACCEPTED, CHECKED_IN, CHECKED_OUT].includes(item)
        ? [counts.accepted, counts.checked_in, counts.checked_out].reduce((prev, cur) => prev + cur, 0)
        : counts[status];

    return {
      key: BOOKING_STATUS[item],
      label: (
        <>
          {t(`common.${status}_tab`, { count })}
          {item === BOOKING_STATUS.PENDING && count > 0 && (
            <FaBell size='14px' className='text-yellow-500 animate-ping inline ml-2' />
          )}
        </>
      ),
    };
  });

  return <CommonTabs tabs={tabs} activeTab={activeTab} handleTabClick={onChange} />;
};

export default Tabs;
