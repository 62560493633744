import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_VIP_PROMOS } from '../../../graphql/queries/vip-promos';
import { logError } from '../../../helpers/errors/bug-report';
import { Link } from 'react-router-dom';
import { RouteConstants } from '../../routes/route-constants';
import classNames from 'classnames';
import { max } from 'lodash';
import { ModalSection } from './modal_section';
import { generatePath } from 'react-router';
import { ModalLink } from './modal_link';
import OfferLink from './offer-link';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';
import { DELETE_LINK, DELETE_OFFER, DELETE_SECTION } from 'graphql/mutations/vip';
import { toast } from 'react-toastify';
import { useModal } from 'components/global-hooks';
import { UI_MODALS } from 'helpers/enums';

import S from './vip-promos.module.scss';

const VipPromosPage = () => {
  const { t } = useTranslation();
  const EMPTY_SECTION: VipSectionCreate = {
    name: { en: '', es: '' },
    channel: 'BOTH',
    sort_order: 0,
    is_active: true,
    icon: '',
  };
  const EMPTY_LINK: VipOfferLinkCreate = {
    name: { en: '', es: '' },
    link_url: '',
    link_type: 'link',
    sort_order: 0,
    offer_id: 0,
  };
  const [visibleLinks, setVisibleLinks] = useState<number[]>([]);
  const [sectionModalData, setSectionModalData] = useState<VipSectionCreate | VipSectionUpdate>(EMPTY_SECTION);
  const [isOpenSectionModal, setIsOpenSectionModal] = useState<boolean>(false);
  const [linkModalData, setLinkModalData] = useState<VipOfferLinkCreate | VipOfferLinkUpdate>(EMPTY_LINK);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState<boolean>(false);
  const [viewLanguage, setViewLanguage] = useState<'en' | 'es'>('en');
  const { initModal, closeModal } = useModal();

  const { data, loading } = useQuery<{ vip_sections: VipSection[] }>(GET_VIP_PROMOS, {
    fetchPolicy: 'network-only',
    onError: (err) => logError(err, 'GET_VIP_PROMOS', VipPromosPage.displayName),
  });

  const confirmAction = (action: any) => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('common.action_cannot_be_undone'),
      message: t('common.are_you_sure'),
      onConfirmation: () => {
        closeModal();
        action();
      },
    });
  };

  const handleError = (message: string) => {
    toast.error(t(message));
  };

  const handleSuccess = (message: string) => {
    toast.success(t(message));
  };

  const [deleteSection, { loading: isDeletingSection }] = useMutation(DELETE_SECTION, {
    onCompleted: handleSuccess.bind(this, 'vip.section.delete.success'),
    refetchQueries: ['GetVipPromos', { query: GET_VIP_PROMOS }],
    onError: handleError.bind(this, 'vip.section.delete.failure'),
  });

  const [deleteOffer, { loading: isDeletingOffer }] = useMutation(DELETE_OFFER, {
    onCompleted: handleSuccess.bind(this, 'vip.offer.delete.success'),
    refetchQueries: ['GetVipPromos', { query: GET_VIP_PROMOS }],
    onError: handleError.bind(this, 'vip.offer.delete.failure'),
  });

  const [deleteLink, { loading: isDeletingLink }] = useMutation(DELETE_LINK, {
    onCompleted: handleSuccess.bind(this, 'vip.offer.link.delete.success'),
    refetchQueries: ['GetVipPromos', { query: GET_VIP_PROMOS }],
    onError: handleError.bind(this, 'vip.offer.link.delete.failure'),
  });

  const handleDeleteAction = (action: any, id: number) => {
    confirmAction(() => action({ variables: { input: { id } } }));
  };

  const hideShowLinks = (offerId: number) => {
    if (visibleLinks.includes(offerId)) {
      setVisibleLinks(visibleLinks.filter((id) => id !== offerId));
    } else {
      setVisibleLinks([...visibleLinks, offerId]);
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t('vip.title')}>
        <button
          onClick={() => {
            setSectionModalData({
              ...EMPTY_SECTION,
              sort_order: max(data?.vip_sections?.map((section) => section.sort_order)) ?? 0 + 1,
            });
            setIsOpenSectionModal(true);
          }}
          className='inline w-40 py-2 border border-transparent text-sm text-center font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          + {t('vip.section.add.title')}
        </button>
      </PageHeader>
      <PageBody isLoading={isDeletingSection || isDeletingOffer || isDeletingLink} className={S.list}>
        <div>
          <label className='block text-sm font-medium text-gray-700'>{t('common.language')}</label>
          <fieldset>
            <div className='space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'>
              <div className='flex items-center'>
                <input
                  id='en'
                  name='language'
                  type='radio'
                  checked={viewLanguage === 'en'}
                  onChange={() => setViewLanguage('en')}
                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
                />
                <label htmlFor='en' className='ml-3 pt-2 block text-sm font-medium text-gray-300'>
                  {` ${t('common.english')} `}
                </label>
              </div>
              <div className='flex items-center'>
                <input
                  id='es'
                  name='language'
                  type='radio'
                  checked={viewLanguage === 'es'}
                  onChange={() => setViewLanguage('es')}
                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
                />
                <label htmlFor='sms' className='ml-3 pt-2 block text-sm font-medium text-gray-300'>
                  {` ${t('common.spanish')} `}
                </label>
              </div>
            </div>
          </fieldset>
        </div>

        <ModalSection
          initialValues={sectionModalData}
          isOpen={isOpenSectionModal}
          title={sectionModalData.hasOwnProperty('id') ? t('vip.edit.title') : t('vip.create.title')}
          closeModal={() => setIsOpenSectionModal(false)}
        />
        <ModalLink
          initialValues={linkModalData}
          isOpen={isOpenLinkModal}
          title={linkModalData.hasOwnProperty('id') ? t('vip.offer.link.edit.title') : t('vip.offer.link.new')}
          closeModal={() => setIsOpenLinkModal(false)}
        />
        {loading || (!data && <LoadingDiscoWithContainer />)}
        {data &&
          data.vip_sections.map((section) => (
            <div key={section.id} className='w-full mt-2 mb-2'>
              <div className='group w-full flex justify-between p-4 bg-gray-600'>
                <div>
                  <div className='w-full text-gray-400 text-sm'>{t('vip.section.title')}</div>
                  <div className='w-full text-gray-300 text-2xl font-bold'>{section.name[viewLanguage]}</div>
                  <div className='w-full text-gray-300 text-lg font-bold '>{section.channel}</div>
                </div>
                <div className='invisible group-hover:visible flex flex-col justify-between'>
                  <button
                    onClick={() => {
                      setSectionModalData({
                        name: {
                          en: section.name.en,
                          es: section.name.es,
                        },
                        channel: section.channel,
                        sort_order: section.sort_order,
                        is_active: section.is_active,
                        icon: section.icon,
                        id: section.id,
                      });
                      setIsOpenSectionModal(true);
                    }}
                    className='w-40 min-h-8 px-2 py-2 leading-4 mr-4  border border-transparent text-sm font-medium rounded-md shadow-sm text-white text-center border-indigo-400 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    {t('vip.section.edit.title')}
                  </button>

                  <button
                    className='w-40 min-h-8 px-2 py-2 leading-4 mr-4 mt-2 mb-2  border border-transparent text-sm font-medium rounded-md shadow-sm text-white text-center border-red-400 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                    onClick={handleDeleteAction.bind(this, deleteSection, section.id)}
                  >
                    {t('vip.section.delete.title')}
                  </button>

                  <Link
                    to={{
                      pathname: generatePath(RouteConstants.vipOfferAdd, { sectionId: section.id }),
                      state: { sortOrder: max(section.offers?.map((offer) => offer.sort_order)) ?? 0 + 1 },
                    }}
                    className='w-40 h-8 mr-4 py-1 border border-transparent text-sm text-center font-medium rounded-md shadow-sm text-white border-green-600 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                  >
                    {t('vip.offer.add')}
                  </Link>
                </div>
              </div>
              <div className='w-full'>
                {section.offers.map((offer, idx) => (
                  <div
                    key={offer.id}
                    className={classNames([
                      'w-full p-4 bg-gray-800 hover:bg-stone-800',
                      idx % 2 !== 0 && 'bg-gray-700 hover:bg-stone-700',
                    ])}
                  >
                    <div className='group w-full flex flex-col p-2'>
                      <div>
                        <div className='w-full text-gray-500 text-sm'>{t('vip.offer.title')}</div>
                        <div className='w-full h-30 p-2 flex justify-between'>
                          <div className='flex w-3/4 min-w-2/3 h-28 p-2'>
                            {offer.image ? (
                              <img src={offer.image.medium} className='w-24 h-24 mr-2' />
                            ) : (
                              <img
                                src='https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-7.jpg'
                                alt='photo'
                                className='h-24 w-24 mr-2 object-contain'
                              />
                            )}
                            <div className='flex flex-col'>
                              <div className='w-full text-gray-300 text-xl font-bold mb-1'>
                                {offer.name[viewLanguage]}
                              </div>
                              <div className='w-full'>{offer.description[viewLanguage]}</div>
                            </div>
                          </div>
                          <div className='invisible group-hover:visible flex flex-col min-w-40'>
                            <Link
                              to={{
                                pathname: generatePath(RouteConstants.vipOfferEdit, {
                                  sectionId: section.id,
                                  offerId: offer.id,
                                }),
                                state: { offer: offer },
                              }}
                              className='w-40 h-8 mr-4 mb-2 px-2 py-2 leading-4 border border-transparent text-sm text-center font-medium rounded-md shadow-sm text-white border-indigo-600 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            >
                              {t('vip.offer.edit')}
                            </Link>

                            <button
                              className='w-40 min-h-8 px-2 py-2 leading-4 mr-4 mb-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white text-center border-red-400 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                              onClick={handleDeleteAction.bind(this, deleteOffer, offer.id)}
                            >
                              {t('vip.offer.delete.title')}
                            </button>
                            <button
                              onClick={() => {
                                setLinkModalData({ ...EMPTY_LINK, offer_id: offer.id });
                                setIsOpenLinkModal(true);
                              }}
                              className='w-40 min-h-8 px-2 py-2 leading-4 mr-4 border border-transparent text-sm rounded-md shadow-sm text-white border-green-600 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                            >
                              {t('vip.offer.link.add.title')}
                            </button>
                          </div>
                        </div>
                        {offer.links.length ? (
                          <div className='flex w-full'>
                            <div className='text-gray-500 text-sm'>
                              <button onClick={() => hideShowLinks(offer.id)} className='text-blue-300'>
                                {visibleLinks.includes(offer.id) ? t('common.hide') : t('common.show')}
                              </button>{' '}
                              {t('vip.offer.link.plural')} ({offer.links.length})
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {visibleLinks.includes(offer.id) &&
                      offer.links.map((link, idxLink) => (
                        <div
                          key={link.id}
                          className={classNames([
                            'group w-full flex mt-2 p-2 justify-between bg-gray-800 hover:bg-stone-800',
                            idxLink % 2 !== 0 && 'bg-gray-700 hover:bg-stone-700',
                          ])}
                        >
                          <div className=' flex flex-col'>
                            <div className='w-full text-gray-500 text-sm'>Link</div>
                            <div className='w-full text-gray-300 text-lg font-bold'>{link.name[viewLanguage]}</div>
                            <div className='w-full p-2'>{link.link_url}</div>
                            <div className='w-full p-2'>
                              <OfferLink title={link.name[viewLanguage]} type={link.link_type} />
                            </div>
                          </div>
                          <div className='invisible group-hover:visible flex flex-col min-w-40'>
                            <button
                              onClick={() => {
                                setLinkModalData({
                                  name: {
                                    en: link.name.en,
                                    es: link.name.es,
                                  },
                                  link_url: link.link_url,
                                  link_type: link.link_type,
                                  id: link.id,
                                  sort_order: link.sort_order,
                                  offer_id: offer.id,
                                });
                                setIsOpenLinkModal(true);
                              }}
                              className='w-40 min-h-8 px-2 py-2 leading-4 mt-4 mr-4 h-8 border border-transparent text-sm text-center rounded-md shadow-sm text-white text-center border-indigo-600 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            >
                              {t('vip.offer.link.edit.title')}
                            </button>

                            <button
                              className='w-40 min-h-8 px-2 py-2 leading-4 mr-4 mt-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white text-center border-red-400 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                              onClick={handleDeleteAction.bind(this, deleteLink, link.id)}
                            >
                              {t('vip.offer.link.delete.title')}
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </PageBody>
    </PageContainer>
  );
};

VipPromosPage.displayName = 'VipPromosPage';

export { VipPromosPage };
