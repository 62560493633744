import React, { FC, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useFetchMore, useModal } from 'components/global-hooks';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import Button from 'components/common/buttons';

import { GETSTREAM_ADMIN_POSTS } from 'graphql/queries/getstream';
import { RouteConstants } from 'components/routes/route-constants';
import { GetstreamScheduledPost, GetstreamScheduledPostActionPayload, PageInfo } from 'types/getstream';
import PageBody from 'components/common/page/page-body';
import ScheduledPostItem from './Item';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';
import StatusFilter from '../../StatusFilter';
import useActions from './useActions';

import { UI_MODALS, GETSTREAM_ADMIN_POST_STATUS } from 'helpers/enums';

const { PENDING, PAUSED, PUBLISHED, CANCELLED } = GETSTREAM_ADMIN_POST_STATUS;

const ScheduledPostsList: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filter, setFilter] = useState('PENDING');
  const { initModal, closeModal } = useModal();
  const {
    isLoading: isFetchMoreLoading,
    fetchMore: fetchMorePosts,
    error: fetchMoreError,
  } = useFetchMore('getstream_admin_posts');

  const { loading, error, data, refetch, fetchMore } = useQuery<{
    getstream_admin_posts: { nodes: [GetstreamScheduledPost]; pageInfo: PageInfo; totalCount: number };
  }>(GETSTREAM_ADMIN_POSTS, {
    fetchPolicy: 'network-only',
    variables: { first: 20, status: filter },
  });

  const { pausePost, unPausePost, cancelPost, deletePost, isMutating } = useActions(refetch);

  const handleGetMore = () => {
    fetchMorePosts(fetchMore, {
      cursor: data?.getstream_admin_posts.pageInfo.endCursor,
    });
  };

  const handleOnEdit = (payload: GetstreamScheduledPostActionPayload) => {
    history.push(RouteConstants.socialScheduledPostEdit.replace(':postId', payload.variables.input.id));
  };

  const handleOnClone = (payload: GetstreamScheduledPostActionPayload) => {
    history.push(RouteConstants.socialScheduledPostAdd.replace(':postId?', payload.variables.input.id));
  };

  const handleOnDelete = (payload: GetstreamScheduledPostActionPayload) => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('social.scheduler.delete.confirm.title'),
      message: t('social.scheduler.delete.confirm.message'),
      onConfirmation: async () => {
        closeModal();
        await deletePost(payload);
        refetch();
      },
    });
  };

  const handleOnView = (payload: GetstreamScheduledPostActionPayload) => {
    const {
      variables: {
        input: { id },
      },
    } = payload;

    const post = data?.getstream_admin_posts.nodes.find((node: { id: string }) => node.id === id);
    const postData = post && { ...post, ...post.getstream_post };
    initModal(UI_MODALS.SOCIAL_POST, {
      id,
      postData,
    });
  };

  const posts = data?.getstream_admin_posts?.nodes || [];

  useEffect(() => {
    refetch();
  }, [filter]);
  return (
    <PageContainer>
      <PageHeader title={t('admin_nav.admin_posts')}>
        <div className={'flex-1 ml-4'}>
          <Link to={RouteConstants.socialScheduledPostAdd.replace('/:postId?', '')} className={'hover:no-underline'}>
            <Button title={`+ ${t('social.scheduler.add_new')}`} color='indigo' />
          </Link>
        </div>
        <StatusFilter value={filter} onChange={setFilter} options={[PENDING, PAUSED, PUBLISHED, CANCELLED]} />
      </PageHeader>
      <PageBody isLoading={loading || isMutating} error={error && t('errors.generic')}>
        {!loading && posts.length === 0 ? (
          <div className='mt-10 text-center'>{t('social.scheduler.empty_list')}</div>
        ) : (
          <div className='my-10'>
            {posts.map((post: GetstreamScheduledPost) => (
              <ScheduledPostItem
                key={post.id}
                post={post}
                onUnpause={unPausePost}
                onPause={pausePost}
                onEdit={handleOnEdit}
                onCancel={cancelPost}
                onDelete={handleOnDelete}
                onClone={handleOnClone}
                onView={handleOnView}
              />
            ))}
          </div>
        )}

        {data?.getstream_admin_posts.pageInfo.hasNextPage && (
          <div className='mt-10 mb-2 text-center'>
            <LoadMoreButton isLoading={isFetchMoreLoading} onClick={handleGetMore} />
          </div>
        )}
      </PageBody>
    </PageContainer>
  );
};

export default ScheduledPostsList;
