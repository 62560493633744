import { useState } from 'react';
import { DobChangeList } from './dob-change-list';
import classNames from 'classnames';
import { VERIFICATION_STAGES } from '../../../helpers/enums';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';

import S from './dob-change-page.module.scss';

const DobChangePage = () => {
  const { t } = useTranslation();
  const [statusView, setStatusView] = useState<string>(VERIFICATION_STAGES.PENDING);
  return (
    <PageContainer>
      <PageHeader title={t('dobChangePage.title')}>
        <span className='isolate inline-flex rounded-md shadow-sm'>
          <button
            type='button'
            onClick={() => setStatusView(VERIFICATION_STAGES.PENDING)}
            className={classNames(
              'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              statusView === VERIFICATION_STAGES.PENDING && 'bg-indigo-200'
            )}
          >
            {t('common.pending')}
          </button>
          <button
            type='button'
            onClick={() => setStatusView(VERIFICATION_STAGES.ACCEPTED)}
            className={classNames(
              'relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              statusView === VERIFICATION_STAGES.ACCEPTED && 'bg-indigo-200'
            )}
          >
            {t('common.accepted')}
          </button>
          <button
            type='button'
            onClick={() => setStatusView(VERIFICATION_STAGES.REJECTED)}
            className={classNames(
              'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
              statusView === VERIFICATION_STAGES.REJECTED && 'bg-indigo-200'
            )}
          >
            {t('common.rejected')}
          </button>
        </span>
      </PageHeader>
      <PageBody isLoading={false} className={S.list}>
        <DobChangeList status={statusView} />
      </PageBody>
    </PageContainer>
  );
};

DobChangePage.displayName = 'DobChangePage';

export { DobChangePage };
