import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import ModalFeedPhoto from '../../modals/modal_feed_photo';
import { RouteConstants } from 'components/routes/route-constants';
import { useHistory } from 'react-router';
import { TranslateButton } from 'components/common/translate_button';
import { ButtonSpinner } from 'components/common/button_spinner';
import { useTranslation } from 'react-i18next';

import S from './offer-form.module.scss';

interface Props {
  pageTitle: string;
  submitHandler: (values: any) => void;
  isSubmitting: boolean;
  initialValues: VipOfferCreate | VipOfferUpdate;
}
const OfferForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { submitHandler, initialValues, pageTitle, isSubmitting } = props;
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);

  const feedSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required(t('errors.validation.required', { field_name: t('common.name') })),
      es: Yup.string().required(t('errors.validation.required', { field_name: t('common.name') })),
    }),
    description: Yup.object().shape({
      en: Yup.string().nullable(),
      es: Yup.string().nullable(),
    }),
    image_input: Yup.object()
      .shape({
        data: Yup.string().nullable(),
        file_name: Yup.string().nullable(),
        mime_type: Yup.string().nullable(),
      })
      .nullable(),
    sort_order: Yup.number()
      .integer(t('errors.validation.not_integer'))
      .min(0, t('errors.validation.min', { number: 0 }))
      .max(9999, t('errors.validation.max', { number: 9999 }))
      .required(t('errors.validation.required', { field_name: t('common.sort_order') })),
  });

  return (
    <div className={`container mt-4 pb-2 px-8 ${S.list}`}>
      <div className='w-full text-gray-300 text-2xl font-bold'>{pageTitle}</div>
      <Formik initialValues={initialValues} validationSchema={feedSchema} onSubmit={submitHandler}>
        {({ values, errors, touched, setFieldValue, isValid }) => {
          console.log('errors', errors);
          return (
            <Form className='space-y-8 divide-y divide-gray-200'>
              <div className='space-y-8 divide-y divide-gray-200'>
                <div>
                  <div>
                    <p className='mt-1 text-sm text-gray-500'>{t('vip.offer.warning_public_info')}</p>
                  </div>

                  <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='sm:col-span-3'>
                      <label htmlFor='photo' className='block text-sm font-medium text-gray-500'>
                        {t('vip.offer.photo')}
                      </label>
                      <div className='mt-1 flex items-center'>
                        <span className='h-96 w-96 mr-4'>
                          {values.image_input && values.image_input.data ? (
                            <img src={values.image_input.data} alt='photo' className='h-96 w-96 object-contain' />
                          ) : (
                            <img
                              src='https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-7.jpg'
                              alt='photo'
                              className='h-96 w-96 object-contain'
                            />
                          )}
                        </span>
                      </div>
                      <div className='w-96 bg-white py-2 px-3 border border-gray-300 rounded-none rounded-b-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                        <ModalFeedPhoto
                          isOpen={openPhotoModal}
                          closeModal={() => setOpenPhotoModal(false)}
                          imageData={values.image_input}
                          canDeletePhoto={true}
                          setImageData={(imageData) => setFieldValue('image_input', { ...imageData })}
                        />
                        <button
                          type='button'
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenPhotoModal(true);
                          }}
                          className='w-full block self-center space-x-2 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          {values.image_input && values.image_input.data
                            ? t('vip.offer.change_crop')
                            : t('vip.offer.upload_crop')}
                        </button>
                        <ErrorMessage name='image_input.data' className='text-red-500 text-sm' component='div' />
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-6'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>{t('vip.offer.name')}</h3>
                          <p className='mt-1 text-sm text-gray-500'>{t('vip.offer.name_info')}</p>
                        </div>
                        <div className='sm:col-span-4'>
                          <label htmlFor='name.en' className='block text-sm font-medium text-gray-500'>
                            {t('common.english')}
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='name.en'
                              id='name.en'
                              className={classNames(
                                'h-9 px-4 py-2 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.name?.en && errors.name?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='name.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-4'>
                          <label htmlFor='name.es' className='block text-sm font-medium text-gray-500'>
                            {t('common.spanish')}
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='name.es'
                              id='name.es'
                              className={classNames(
                                'h-9 px-4 py-2 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.name?.es && errors.name?.es }
                              )}
                            />
                            <TranslateButton
                              stringOriginal={values.name.en}
                              langFrom='en'
                              langTo='es'
                              flatLeft
                              onTranslate={(translatedString) => setFieldValue('name.es', translatedString)}
                            />
                          </div>
                          <ErrorMessage name='name.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-6'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>{t('common.description')}</h3>
                          <p className='mt-1 text-sm text-gray-500'>{t('vip.offer.description_info')}</p>
                        </div>
                        <div className='sm:col-span-6'>
                          <label htmlFor='description.en' className='block text-sm font-medium text-gray-500'>
                            {t('common.english')}
                          </label>
                          <div className='mt-1'>
                            <Field
                              as='textarea'
                              id='description.en'
                              name='description.en'
                              rows={5}
                              className={classNames(
                                'px-4 py-2 shadow-sm text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md',
                                { ['border-2  border-red-500']: touched.description?.en && errors.description?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='description.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-6'>
                          <label htmlFor='description.es' className='block text-sm font-medium text-gray-500'>
                            {t('common.spanish')}
                          </label>
                          <div className='mt-1'>
                            <div className='relative flex items-stretch flex-grow focus-within:z-10 mb-4'>
                              <Field
                                as='textarea'
                                id='description.es'
                                name='description.es'
                                rows={5}
                                className={classNames(
                                  'px-4 py-2 shadow-sm text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md',
                                  { ['border-2  border-red-500']: touched.description?.es && errors.description?.es }
                                )}
                              />
                            </div>
                            <TranslateButton
                              stringOriginal={values.description.en}
                              langFrom='en'
                              langTo='es'
                              onTranslate={(translatedString) => setFieldValue('description.es', translatedString)}
                            />
                          </div>
                          <ErrorMessage name='description.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    <div className='sm:col-span-3 mb-2'>
                      <label htmlFor='sort_order' className='block text-sm font-medium text-gray-500'>
                        {t('common.sort_order')}
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <Field
                          type='number'
                          name='sort_order'
                          id='sort_order'
                          className={classNames(
                            'h-9 px-4 py-2 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                            { ['border-2  border-red-500']: touched.sort_order && errors.sort_order }
                          )}
                        />
                      </div>
                      <ErrorMessage name='sort_order' className='text-red-500 text-sm' component='div' />
                    </div>

                    <div className='sm:col-span-4'>
                      <label htmlFor='is_active' className='block text-sm font-medium text-gray-500'>
                        {t('common.active')}
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <Field
                          name='is_active'
                          id='is_active'
                          className='flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        >
                          {() => (
                            <button
                              type='button'
                              onClick={() => setFieldValue('is_active', !values.is_active)}
                              className={classNames(
                                values.is_active ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              )}
                              role='switch'
                              aria-checked='false'
                            >
                              <span className='sr-only'>{t('common.active')}?</span>
                              <span
                                aria-hidden='true'
                                className={classNames(
                                  values.is_active ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                              ></span>
                            </button>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='pt-5'>
                <div className='flex justify-end'>
                  <button
                    type='button'
                    onClick={() => history.push(RouteConstants.vipPromos)}
                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    {t('common.cancel')}
                  </button>
                  <button
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={classNames(
                      { ['bg-indigo-600 hover:bg-indigo-700']: isValid },
                      { ['bg-gray-600']: !isValid },
                      'w-40 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    {isSubmitting && <ButtonSpinner />} {t('common.submit')}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

OfferForm.displayName = 'OfferForm';

export { OfferForm };
