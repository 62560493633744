import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ApolloError } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import camelCase from 'lodash/camelCase';

import {
  SHIFT_APPLICATION_ACCEPT_CANCEL_GQL,
  SHIFT_APPLICATION_ACCEPT_GQL,
} from 'graphql/mutations/shift-application-accept';

import {
  SHIFT_APPLICATION_REJECT_CANCEL_GQL,
  SHIFT_APPLICATION_REJECT_GQL,
} from 'graphql/mutations/shift-application-reject';
import { SHIFT_STANDBY_CANCEL_GQL, SHIFT_STANDBY_GQL } from 'graphql/mutations/shift-application-standby';
import { BOOKING_CHECK_IN_GQL } from 'graphql/mutations/shift-application-check-in';
import { BOOKING_CANCEL_CHECK_IN_GQL } from 'graphql/mutations/shift-application-check-in-undo';
import { BOOKING_CHECK_OUT_GQL } from 'graphql/mutations/shift-application-check-out';
import { BOOKING_CANCEL_CHECK_OUT_GQL } from 'graphql/mutations/shift-application-check-out-undo';

import {
  SHIFT_APPLICATION_FAILED_TO_ATTEND_CANCEL_GQL,
  SHIFT_APPLICATION_FAILED_TO_ATTEND_GQL,
} from 'graphql/mutations/shift-application-failed-to-attend';
import { SHIFT_APPLICATION_INVITE_CANCEL_GQL } from 'graphql/mutations/shift-application-invite-cancel';

import { ActionMapType, ActionResult, MutationResult } from './types';

const useActions = (onActionSuccess: (ids: string[], action: string) => void) => {
  const { t } = useTranslation();

  const handleSuccesses = (successes: ActionResult[], action: string) => {
    const successIds = successes.map(({ shift_application: { id } }) => id);
    onActionSuccess(successIds, action);
    let key = action;
    if (action === 'pending' && successes[0].shift_application.is_invite) {
      key = 'invited';
    }
    toast.success(t(`manageBookings.${key}.success`));
  };

  const handleFailures = (failures: ActionResult[]) => {
    failures.forEach((failure) => {
      handleOnError(failure);
    });
  };

  const handleOnError = (error: ApolloError | ActionResult) => {
    let toastMessage;
    switch (error.message) {
      case 'CONFLICTING_SHIFTS':
        toastMessage = 'manageBookings.accept.failure.CONFLICTING_SHIFTS';
        break;
      case 'SHIFT_HAS_ENDED':
        toastMessage = 'manageBookings.checkIn.failure.SHIFT_HAS_ENDED';
        break;
      case 'SHIFT_APPLICATION_NOT_ACCEPTED':
        toastMessage = 'manageBookings.common.failure.SHIT_APPLICATION_NOT_ACCEPTED';
        break;
      case 'SHIFT_AT_SLOT_LIMIT':
        toastMessage = 'manageBookings.common.failure.SHIFT_AT_SLOT_LIMIT';
        break;
      default:
        toastMessage = 'manageBookings.shift_update_failed.default';
        break;
    }
    toast.error(t(toastMessage));
  };

  const handleOnCompleted = (data: MutationResult) => {
    const field = Object.keys(data)[0];
    const action = camelCase(field.split('shift_application_')[1]);
    const { successes, failures } = data[field];
    if (failures.length) {
      handleFailures(failures);
    }
    if (successes.length) {
      handleSuccesses(successes, action);
    }
  };

  const options = {
    onError: handleOnError,
    onCompleted: handleOnCompleted,
  };

  const [ACCEPT, { loading: accepting }] = useMutation(SHIFT_APPLICATION_ACCEPT_GQL, options);
  const [ACCEPT_CANCEL, { loading: cancellingAccept }] = useMutation(SHIFT_APPLICATION_ACCEPT_CANCEL_GQL, options);
  const [REJECT, { loading: rejecting }] = useMutation(SHIFT_APPLICATION_REJECT_GQL, options);
  const [REJECT_CANCEL, { loading: cancellingReject }] = useMutation(SHIFT_APPLICATION_REJECT_CANCEL_GQL, options);
  const [STANDBY, { loading: standbying }] = useMutation(SHIFT_STANDBY_GQL, options);
  const [STANDBY_CANCEL, { loading: cancellingStandby }] = useMutation(SHIFT_STANDBY_CANCEL_GQL, options);
  const [CHECK_IN, { loading: checkingIn }] = useMutation(BOOKING_CHECK_IN_GQL, options);
  const [CHECK_IN_CANCEL, { loading: cancellingCheckIn }] = useMutation(BOOKING_CANCEL_CHECK_IN_GQL, options);
  const [CHECK_OUT, { loading: checkingOut }] = useMutation(BOOKING_CHECK_OUT_GQL, options);
  const [CHECK_OUT_CANCEL, { loading: cancellingCheckOut }] = useMutation(BOOKING_CANCEL_CHECK_OUT_GQL, options);
  const [INVITE_CANCEL, { loading: cancellingInvite }] = useMutation(SHIFT_APPLICATION_INVITE_CANCEL_GQL, options);
  const [FAILED_TO_ATTEND, { loading: failingToAttend }] = useMutation(SHIFT_APPLICATION_FAILED_TO_ATTEND_GQL, options);
  const [FAILED_TO_ATTEND_CANCEL, { loading: cancellingFailedToAttend }] = useMutation(
    SHIFT_APPLICATION_FAILED_TO_ATTEND_CANCEL_GQL,
    options
  );

  const actions: ActionMapType = {
    ACCEPT,
    ACCEPT_CANCEL,
    REJECT,
    REJECT_CANCEL,
    STANDBY,
    STANDBY_CANCEL,
    CHECK_IN,
    CHECK_IN_CANCEL,
    CHECK_OUT,
    CHECK_OUT_CANCEL,
    FAILED_TO_ATTEND,
    FAILED_TO_ATTEND_CANCEL,
    INVITE_CANCEL,
  };

  const mutate = async (action: string, idArray: string[]) => {
    await (actions as ActionMapType)[action]({ variables: { idArray } });
  };

  return {
    mutate,
    isLoading:
      accepting ||
      cancellingAccept ||
      checkingIn ||
      rejecting ||
      cancellingReject ||
      standbying ||
      cancellingStandby ||
      checkingIn ||
      cancellingCheckIn ||
      checkingOut ||
      cancellingCheckOut ||
      failingToAttend ||
      cancellingFailedToAttend ||
      cancellingInvite,
  };
};

export default useActions;
