import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { FaPen, FaTrash } from 'react-icons/fa';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';

import S from './booking-modal-header.module.scss';
import { setMetaFields } from '../modal-booking-actions';
import { useModalBookingCtx } from '../modal-booking-context';
import { BookingIcon } from '../../../common/icons/booking-icon';
import { RouteCreator } from '../../../routes/route-constants';
import { useBusiness, useHasPermission } from '../../../../graphql/graph-hooks';
import { PERMISSIONS } from '../../../../helpers/enums';
import * as TimeHelpers from '../../../../helpers/times';

const BookingModalHeader = ({ bookingId, isAudition, handleDelete }) => {
  const CAN_MANAGE_BOOKINGS = useHasPermission(PERMISSIONS.MANAGE_BOOKINGS);

  const { state, dispatch } = useModalBookingCtx();
  const { t, i18n } = useTranslation();
  const { timezone } = useBusiness();
  const IS_EDIT = state.meta.isEditable;
  const SHOW_OPTIONS = state.meta.hasOptions;
  const canManagePastBookings = posthog.isFeatureEnabled('create-past-bookings');
  const {
    recurring,
    recurring_shift,
    pending_shift_applications_count,
    accepted_shift_applications_count,
    checked_in_shift_applications_count,
    checked_out_shift_applications_count,
    has_applications,
  } = state.meta.initial;

  const orderDays = (shifts = [], timezone) => {
    return shifts
      .map(({ start_time }) => moment.tz(start_time, timezone).format('e'))
      .sort()
      .map((dayNum) => TimeHelpers.getDayFromIndex(dayNum, 'dddd', i18n.language))
      .join(', ');
  };

  const ACCEPTED_COUNT = useMemo(
    () =>
      accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count,
    [accepted_shift_applications_count, checked_in_shift_applications_count, checked_out_shift_applications_count]
  );

  const HEADING = () => {
    if (!bookingId) {
      return t('bookingCalendar.create_booking');
    } else if (IS_EDIT) {
      return t('bookingCalendar.edit_booking');
    } else {
      return t('common.booking');
    }
  };

  const HAS_ENDED = moment.tz(moment(), timezone).isAfter(state.meta.initial.end_time);

  const CAN_EDIT_BOOKING = useMemo(
    () => CAN_MANAGE_BOOKINGS && (!HAS_ENDED || canManagePastBookings),
    [CAN_MANAGE_BOOKINGS, HAS_ENDED, canManagePastBookings]
  );

  const handleEdit = useCallback(() => dispatch(setMetaFields({ isEditable: true })), [dispatch]);

  return (
    <div className={S.bookingModalHeader}>
      <div className={S.secondRow}>
        <h3 className={S.modalHeading}>{HEADING()}</h3>
        {bookingId && !IS_EDIT && SHOW_OPTIONS && (
          <div className={S.bookingIconArea}>
            {CAN_EDIT_BOOKING && <FaPen onClick={handleEdit} className={S.svg} />}

            {has_applications && (
              <Link to={RouteCreator.applicationRequests(bookingId, isAudition)}>
                <BookingIcon
                  pendingCount={pending_shift_applications_count}
                  hasEnded={HAS_ENDED && ACCEPTED_COUNT > 0}
                />
              </Link>
            )}

            {CAN_EDIT_BOOKING && SHOW_OPTIONS && <FaTrash onClick={handleDelete} className={S.svg} />}
          </div>
        )}
      </div>
      {recurring && recurring_shift?.siblings && (
        <p>{t('common.repeats_every', { days: orderDays(recurring_shift.siblings, timezone) })}</p>
      )}
    </div>
  );
};

BookingModalHeader.displayName = 'BookingModalHeader';

BookingModalHeader.propTypes = {
  bookingId: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

BookingModalHeader.defaultProps = {
  bookingId: null,
};

export { BookingModalHeader };
