import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBusiness } from 'graphql/graph-hooks';

import { getBookingDateString } from 'components/pages-login/ApplicationRequestsPage/helpers';
import Actions from 'components/pages-login/ApplicationRequestsPage/Actions';

import { ShiftApplication } from 'components/pages-login/ApplicationRequestsPage/types';

import S from './ApplicationActions.module.scss';

interface Props {
  application: ShiftApplication;
  onActionSuccess: (ids: string[], action: string) => void;
}

const ApplicationActions: FC<Props> = ({ application, onActionSuccess }) => {
  const { t, i18n } = useTranslation();
  const business = useBusiness();

  const {
    id,
    status,
    shift: {
      start_time,
      end_time,
      shift_type,
      slots,
      hardcapped,
      accepted_shift_applications_count,
      checked_out_shift_applications_count,
      checked_in_shift_applications_count,
    },
  } = application;

  const filledSlots =
    accepted_shift_applications_count + checked_out_shift_applications_count + checked_in_shift_applications_count;
  const slotsAvailable = hardcapped ? Infinity : slots - filledSlots;
  const formattedDate = useMemo(
    () =>
      getBookingDateString({ start: start_time, end: end_time, timezone: business.timezone, language: i18n.language }),
    [application.shift, i18n.language]
  );

  const handleOnActionSuccess = (ids: string[], action: string) => {
    if (onActionSuccess) {
      onActionSuccess(ids, action);
    }
  };

  return (
    <div>
      <div className={S.info}>
        <div className='font-bold'>{t(`common.${status.toLowerCase()}`)}</div>
        <div>
          {t(`bookingCalendar.${shift_type}`)} {`(${filledSlots}/${slotsAvailable})`}
        </div>
        <div>{formattedDate}</div>
      </div>
      <div className={S.actionRow}>
        <Actions
          bookingId={application.shift.id}
          applications={[application]}
          onActionSuccess={handleOnActionSuccess}
        />
      </div>
    </div>
  );
};

export default ApplicationActions;
