import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const PageContainer: FC<Props> = ({ className = '', children }) => {
  return <div className={`min-h-full mt-16 mx-10 ${className}`}>{children}</div>;
};

export default PageContainer;
