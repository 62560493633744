import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import S from './booking-modal.module.scss';
import { BookingForm } from './booking-form/booking-form';
import { BookingModalHeader } from './booking-modal-header';
import { RecurringConfirmationModal } from './confirmation-modal';
import * as BookingModalActions from '../modal-booking-actions';
import * as BookingModalConstants from '../modal-booking-constants';
import { useModalBookingCtx } from '../modal-booking-context';
import { CloseButton } from '../../../common/buttons/close-button';
import { LoadingDisco, LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useBusiness } from '../../../../graphql/graph-hooks';
import { DELETE_SHIFT_GQL } from '../../../../graphql/mutations/shift-delete';
import { GET_GROUP_NAME_LIST } from '../../../../graphql/queries/group-name-list';
import { GET_SHIFT_BY_ID } from '../../../../graphql/queries/shift-by-id';
import { logError } from '../../../../helpers/errors/bug-report';
import { useOverlayClick } from '../../../../helpers/hooks/use-overlay-click';
import * as TimeHelpers from '../../../../helpers/times';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const BookingModel = ({ bookingId, onClose, start_time, end_time, refetch }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const MODAL_REF = useRef();

  useOverlayClick(MODAL_REF, !isLoading && onClose);
  const { id, timezone } = useBusiness();
  const {
    state: {
      meta: { initial, confirm },
    },
    dispatch,
  } = useModalBookingCtx();

  const onCompleted = useCallback(async () => {
    dispatch(
      BookingModalActions.setMetaFields({
        isEditable: false,
        hasOptions: false,
      })
    );
    refetch();
    onClose();
  }, [refetch, onClose, dispatch]);

  useEffect(() => {
    dispatch(
      BookingModalActions.setMetaFields({
        initial: { start_time, end_time },
        isEditable: !bookingId,
      })
    );

    if (!bookingId) {
      dispatch(
        BookingModalActions.setFormFields({
          start_time: TimeHelpers.getFormattedTimeZone(start_time, timezone, 'hh:mmA'),
          end_time: TimeHelpers.getFormattedTimeZone(end_time, timezone, 'hh:mmA'),
          date: TimeHelpers.createMoment(start_time, timezone).toISOString(),
        })
      );
    }
  }, [dispatch, bookingId, start_time, end_time, timezone]);

  const { data, loading, error } = useQuery(GET_SHIFT_BY_ID, {
    fetchPolicy: 'network-only',
    variables: { id: bookingId ? bookingId : undefined },
    skip: !bookingId,
    onError: (err) => logError(err, 'GET_SHIFT_BY_ID', BookingModel.displayName),
    onCompleted: (data) => {
      if (data) {
        dispatch(BookingModalActions.setMetaFields({ initial: data.shift }));
        dispatch(
          BookingModalActions.setFormFields({
            bookingId: data.shift.id,
            date: data.shift.start_time,
            start_time: TimeHelpers.getFormattedTimeZone(data.shift.start_time, timezone, 'hh:mmA'),
            end_time: TimeHelpers.getFormattedTimeZone(data.shift.end_time, timezone, 'hh:mmA'),
            slots: data.shift.slots.toString(),
            booking_type: data.shift.shift_type,
            title: data.shift.title,
            description: data.shift.description,
            preview: data.shift.first_image?.medium,
            preapprovedIds: data.shift.pre_approved_groups.map(({ id }) => id),
            checkOut: data.shift.auto_checkout_interval ? String(data.shift.auto_checkout_interval) : '0',
            hardCap: data.shift.hardcapped,
            recurring: data.shift.recurring,
          })
        );
      }
    },
  });

  const [deleteBooking] = useMutation(DELETE_SHIFT_GQL, {
    variables: { bookingId },
    onError: (err) => {
      let message = t('errors.generic');
      if (err.toString() === 'Error: GraphQL error: Not authorized.') {
        message = t('errors.401');
      }
      toast.error(message);
    },
    onCompleted,
  });

  const handleDeleteClick = useCallback(async () => {
    if (initial.recurring) {
      dispatch(
        BookingModalActions.setMetaFields({
          confirm: BookingModalConstants.BOOKING_CONFIRM_ACTIONS.DELETE,
        })
      );
    } else
      await deleteBooking({
        variables: {
          recurring: false,
          separateSeries: false,
        },
      });
  }, [dispatch, initial.recurring, deleteBooking]);

  const getGroups = useQuery(GET_GROUP_NAME_LIST, {
    fetchPolicy: 'network-only',
    variables: { businessId: id },
    onError: (err) => logError(err, 'GET_GROUP_NAME_LIST', BookingModel.displayName),
  });

  if (loading || getGroups.loading) {
    return (
      <div ref={MODAL_REF}>
        <LoadingDiscoWithContainer />
      </div>
    );
  }

  if (error) {
    return (
      <h3 ref={MODAL_REF} className={S.bookingModalError}>
        Server Error Getting Booking
      </h3>
    );
  }

  return (
    <div ref={MODAL_REF} className={S[`bookingModalWrapper${confirm ? 'Small' : 'Big'}`]}>
      {confirm ? (
        <RecurringConfirmationModal handleDelete={deleteBooking} />
      ) : (
        <>
          <CloseButton onClick={!isLoading && onClose} />
          <BookingModalHeader
            isAudition={data?.shift?.shift_type === 'audition'}
            bookingId={bookingId}
            handleDelete={handleDeleteClick}
          />
          <BookingForm bookingId={bookingId} onClose={onClose} onCompleted={onCompleted} setLoading={setLoading} />
        </>
      )}
    </div>
  );
};

BookingModel.displayName = 'BookingModel';
BookingModel.propTypes = {
  bookingId: PropTypes.string,
  start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end_time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

BookingModel.defaultProps = {
  bookingId: null,
  start: null,
  end: null,
};

export { BookingModel };
