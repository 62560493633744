import React, { useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { DancerAppItem } from './dancer-app-item';
import S from '../../../pages-login/dancers/dancer-details/dancer-detail-styles.module.scss';
import { LoadingDisco } from '../../../common/loading/loading-disco';
import { useBusiness } from '../../../../graphql/graph-hooks';
import { GET_DANCER_DETAILS_GQL } from '../../../../graphql/queries/dancer-shift-applications-query';
import { useInfiniteScroll } from '../../../../helpers/hooks/use-infiinte-scroll';
import { BOOKING_STATUS } from '../../../../helpers/enums';
import { logError } from '../../../../helpers/errors/bug-report';

function getStatuses(status) {
  if (status === 'all') {
    return undefined;
  } else if (status === BOOKING_STATUS.ACCEPTED) {
    return [BOOKING_STATUS.ACCEPTED, BOOKING_STATUS.CHECKED_IN, BOOKING_STATUS.CHECKED_OUT];
  } else {
    return [status];
  }
}

const DancerAppTable = ({ dancerId, activeTab }) => {
  const { t } = useTranslation();
  let TABLE_REF = useRef(null);
  const { id: businessId, timezone } = useBusiness();
  const [pageInfo, setPageInfo] = useState({ endCursor: '', hasNextPage: false });
  const COLUMNS = [t('common.status'), t('common.start'), t('common.end'), t('common.type')];

  const VARIABLES = useMemo(
    () => ({ businessId, dancerId, cursor: '', status: getStatuses(activeTab) }),
    [businessId, dancerId, activeTab]
  );

  const { loading, error, data, fetchMore } = useQuery(GET_DANCER_DETAILS_GQL, {
    fetchPolicy: 'network-only',
    variables: VARIABLES,
    onCompleted: ({ shift_applications }) => setPageInfo(shift_applications.pageInfo),
    onError: (err) => logError(err, GET_DANCER_DETAILS_GQL, DancerAppTable.displayName),
  });

  const getMore = async () =>
    await fetchMore({
      variables: { ...VARIABLES, cursor: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        setPageInfo(fetchMoreResult.shift_applications.pageInfo);

        return {
          shift_applications: {
            ...fetchMoreResult.shift_applications,
            nodes: [...previousResult.shift_applications.nodes, ...fetchMoreResult.shift_applications.nodes],
          },
        };
      },
    });

  const { isLoading, loadMore } = useInfiniteScroll(TABLE_REF, pageInfo.hasNextPage, getMore, 20);

  const NODES = useMemo(() => data?.shift_applications?.nodes ?? [], [data]);

  if (loading) {
    return (
      <div className={S.loadingRow}>
        <LoadingDisco />
      </div>
    );
  }
  if (error) {
    return <label className={S.listError}>{t('errors.unable_to_load_dancer')}</label>;
  }

  return (
    <>
      <div className='text-white text-base my-5'>
        <span className='font-bold mx-2'>{NODES.length}</span>
        {t('common.of')}
        <span className='font-bold mx-2'>{data.shift_applications.totalCount}</span>
        {t('common.bookings')}
      </div>

      <div className={S.bookingTableHeader}>
        {COLUMNS.map((key, i) => (
          <div key={`table_heading_${i}`}>{key}</div>
        ))}
      </div>

      <div ref={TABLE_REF} onScroll={loadMore} className={S.bookingTableBody}>
        {NODES.length === 0 ? (
          <h3>{t('dancerPage.no_bookings_found')}</h3>
        ) : (
          NODES.map((node) => <DancerAppItem key={`application_${node.id}`} timezone={timezone} {...node} />)
        )}

        {isLoading && (
          <div className={S.loadingRow}>
            <LoadingDisco />
          </div>
        )}
      </div>
    </>
  );
};

DancerAppTable.displayName = 'DancerAppTable';
DancerAppTable.propTypes = {
  dancerId: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export { DancerAppTable };
