import * as PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { APIConstants } from '../../../helpers/constants';

import S from './modal-notification.module.scss';
import { useMessageNotificationForm } from './use-message-notification-form';
import { CloseButton } from '../../common/buttons/close-button';
import { FormSelectField } from '../../common/form/form-select-field';
import { FormTextareaField } from '../../common/form/form-textarea-field';
import { FormTextField } from '../../common/form/form-text-field';
import { CREATE_BLAST_GQL } from '../../../graphql/mutations/blast-create';
import { CREATE_GROUP_MSG_GQL } from '../../../graphql/mutations/group-announcement-create';
import { BLAST_ANNOUNCEMENTS_PAGINATED } from 'graphql/queries/blast-get-query';
import { GROUP_ANNOUNCEMENTS } from 'graphql/queries/group-detail-page-query';
import { LOCALES } from '../../../helpers/enums';
import { NOTIFICATION_TYPES } from '../../../helpers/constants';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { checkForFilterError } from '../../../helpers/filtered-words';
import RichToast from 'components/common/rich-toast';
import useSuggestions, { SuggestionListType } from 'helpers/hooks/useSuggestions';
import { FormCheckBox } from '../../common/checkboxes/form-checkbox';

const { EN, ES } = LOCALES;

export const createAppUrl = (path = '') => {
  const { environment, expoServer = '', deeplinkPrefix } = APIConstants;
  const scheme = 'poleposition' + (['dev', 'staging', 'demo'].includes(environment) ? `-${environment}` : '');
  const baseUrl = `${scheme}://${expoServer}${deeplinkPrefix}`;
  return `${baseUrl}/${path}`;
};

const ModalNotification = ({ onClose, id }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  const { checkText } = useSuggestions(SuggestionListType.MANAGER);

  useOverlayClick(MODAL_REF, onClose);

  const OPTIONS = [
    { value: '', label: t('common.select_option') },
    ...[NOTIFICATION_TYPES.WORKERS, NOTIFICATION_TYPES.DANCERS, NOTIFICATION_TYPES.ALL].map((item) => ({
      ...item,
      label: t(item.label),
    })),
  ];

  const URL_TYPE_OPTIONS = [
    { value: '-', label: '-', type: ['all_users', 'all_dancers', 'all_workers'] },
    { value: 'Home', label: t('modals.message.screens.home'), type: ['all_users', 'all_dancers', 'all_workers'] },
    { value: 'ViewMyShifts', label: t('modals.message.screens.entertainersBookings'), type: ['all_dancers'] },
    { value: 'DancerOverview', label: t('modals.message.screens.entertainersProfile'), type: ['all_dancers'] },
    { value: 'Feed', label: t('modals.message.screens.lightningFeed'), type: ['all_dancers'] },
    { value: 'LiveLike', label: t('modals.message.screens.livelike'), type: ['all_dancers'] },
    {
      value: 'Shopping',
      label: t('modals.message.screens.vipRoom'),
      type: ['all_users', 'all_dancers', 'all_workers'],
    },
    { value: 'Courses', label: t('modals.message.screens.courses'), type: ['all_dancers'] },
    { value: 'url', label: t('modals.message.screens.url'), type: ['all_users', 'all_dancers', 'all_workers'] },
  ];

  const {
    values,
    errors,
    langs,
    handleTypeChange,
    handleTitleChange,
    handleMsgChange,
    handleTitleBlur,
    handleMsgBlur,
    handleChange,
    handleBlur,
    handleChangeIncludeInactive,
    validateForm,
  } = useMessageNotificationForm(id);

  const handleError = (error) => {
    const filterError = checkForFilterError(error);
    toast.error(<RichToast title={filterError.title} message={filterError.message} />);
  };

  const prepValues = () => {
    const lan = Object.values(langs);

    if (!values.title[lan[0]] || !values.title[lan[1]]) {
      const enteredLan = values.title[lan[0]] ? lan[0] : lan[1];
      const title = values.title[enteredLan];
      const message = values.message[enteredLan];
      const targetUrl =
        values.urlType === 'url' ? values.targetUrl : values.urlType === '-' ? undefined : createAppUrl(values.urlType);
      return {
        ...values,
        title: { [lan[0]]: title, [lan[1]]: title },
        message: { [lan[0]]: message, [lan[1]]: message },
        targetUrl,
      };
    } else return values;
  };

  const [blastMsg, { loading: processBlast }] = useMutation(CREATE_BLAST_GQL, {
    variables: prepValues(),
    onError: handleError,
    refetchQueries: [
      { query: BLAST_ANNOUNCEMENTS_PAGINATED, variables: { blastType: values.type.toUpperCase(), first: 10 } },
    ],
    onCompleted: () => {
      toast.success(t('modals.message.sent.success'));
      onClose();
    },
  });

  const [notifyGroup, { loading: processGroup }] = useMutation(CREATE_GROUP_MSG_GQL, {
    variables: {
      ...prepValues(),
      groupId: id,
    },
    onError: handleError,
    refetchQueries: [{ query: GROUP_ANNOUNCEMENTS, variables: { groupId: id, first: 10 } }],
    onCompleted: () => {
      toast.success(t('modals.message.sent.success'));
      onClose();
    },
  });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const values = prepValues();
        let textToCheck = '';
        ['title', 'message'].forEach((field) => {
          ['en', 'es'].forEach((language) => {
            textToCheck += ' ' + values[field][language];
          });
        });
        if (id) {
          await checkText(textToCheck);
        }
        if (validateForm()) id ? await notifyGroup() : await blastMsg();
      } catch (error) {
        handleError(error);
      }
    },
    [validateForm, id, blastMsg, notifyGroup]
  );

  return (
    <form className={S.notificationModal} ref={MODAL_REF} onSubmit={handleSubmit}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t(`modals.message.${id ? 'notify_group' : 'blast_notification'}`)}</h3>

      <div>
        {!id && (
          <div className={S.grid}>
            <div className={S.fieldContainer}>
              <FormSelectField
                name='type'
                id='modal_blast_type'
                label={t('modals.message.target_audience')}
                value={values.type}
                error={errors.type}
                isDisabled={processBlast || processGroup}
                handleChange={handleTypeChange}
                options={OPTIONS}
              />
            </div>
            <div className={`${S.fieldContainer} mt-10`}>
              <FormCheckBox
                id='modal_include_inactive'
                label={t('modals.message.include_inactive')}
                name='includeInactive'
                isChecked={!values.excludeInactive}
                handleChange={handleChangeIncludeInactive}
              />
            </div>
          </div>
        )}

        <div className={S.grid}>
          {langs.map((lang) => (
            <div key={lang} className={S.fieldContainer}>
              <FormTextField
                name={lang}
                className={S.inputField}
                id={`modal_blast_title_${lang}`}
                label={`${lang === EN ? t('common.english') : t('common.spanish')} ${t(
                  'modals.message.title'
                ).toLowerCase()}`}
                value={values.title[lang]}
                error={errors.title[lang]}
                isDisabled={processBlast || processGroup}
                handleBlur={handleTitleBlur}
                handleChange={handleTitleChange}
              />
              <div className='mt-6'>
                <FormTextareaField
                  name={lang}
                  className={S.inputField}
                  id={`modal_blast_message_${lang}`}
                  label={`${lang === EN ? t('common.english') : t('common.spanish')} ${t(
                    'modals.message.message'
                  ).toLowerCase()}`}
                  isDisabled={processBlast || processGroup || !values.title[lang]}
                  value={values.title[lang] ? values.message[lang] : ''}
                  error={values.title[lang] && errors.message[lang]}
                  handleBlur={handleMsgBlur}
                  handleChange={handleMsgChange}
                />
              </div>
            </div>
          ))}
        </div>
        {!id && values.type && (
          <div className={S.grid}>
            <div className='w-80 mr-5'>
              <FormSelectField
                name='urlType'
                className={S.inputField}
                id='modal_blast_url_type'
                label={t('modals.message.urlType')}
                value={values.urlType}
                error={errors.urlType}
                isDisabled={processBlast || processGroup}
                handleChange={handleChange}
                options={URL_TYPE_OPTIONS.filter(({ type }) => type.includes(values.type))}
              />
            </div>
            {values.urlType === 'url' && (
              <div className='w-80'>
                <FormTextField
                  name='targetUrl'
                  className={S.inputField}
                  id={'modal_blast_targetUrl'}
                  label={t('modals.message.targetUrl')}
                  value={values.targetUrl ? values.targetUrl : ''}
                  error={errors.targetUrl && t(`errors.validation.${errors.targetUrl}`)}
                  isDisabled={processBlast || processGroup}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            )}
          </div>
        )}
        <div className='mt-8'>
          <button type='submit' className={S.invertedBtnGreen} disabled={processBlast || processGroup}>
            {t('common.submit')}
          </button>
        </div>
      </div>
    </form>
  );
};

ModalNotification.displayName = 'MessageNotificationPresenter';
ModalNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ModalNotification.defaultProps = {
  id: null,
};

export { ModalNotification };
