import { FC } from 'react';

import ActionButton from './ActionButton';
import useActions from './useActions';
import { getActions } from './getActions';

import { BOOKING_STATUS, PERMISSIONS } from 'helpers/enums';
import { useHasPermission } from 'graphql/graph-hooks';
import { ActionsProps } from './types';
import { ACTION_TYPES } from './constants';

const { INVITE_CANCEL, ACCEPT, ACCEPT_CANCEL, STANDBY, STANDBY_CANCEL, REJECT, REJECT_CANCEL } = ACTION_TYPES;

const Actions: FC<ActionsProps> = ({ bookingId, applications, disabled, onActionSuccess }) => {
  const { mutate, isLoading } = useActions(onActionSuccess);
  const CAN_SEND_INVITES = useHasPermission(PERMISSIONS.SEND_INVITES);
  const CAN_CHECK_IN = useHasPermission(PERMISSIONS.CHECK_IN_DANCERS);
  const CAN_MANAGE_REQUESTS = useHasPermission(PERMISSIONS.MANAGE_REQUESTS);
  const permissions = {
    CAN_SEND_INVITES,
    CAN_CHECK_IN,
    CAN_MANAGE_REQUESTS,
  };

  const applicationsIds = applications.map(({ id }) => id);

  const selectedActions = applications?.map(({ status, shift, is_invite }) => {
    const {
      slots,
      hardcapped,
      accepted_shift_applications_count,
      checked_out_shift_applications_count,
      checked_in_shift_applications_count,
      start_time,
      end_time,
    } = shift;
    const slotsAvailable = !hardcapped
      ? Infinity
      : slots -
        (accepted_shift_applications_count +
          checked_out_shift_applications_count +
          checked_in_shift_applications_count);

    const actions = getActions({
      status: is_invite && status === BOOKING_STATUS.PENDING ? BOOKING_STATUS.INVITED : status,
      permissions,
      slotsAvailable,
      appNum: applications.length,
      start_time,
      end_time,
      isInvite: is_invite,
    });

    return bookingId
      ? actions
      : actions.filter((action) =>
          [INVITE_CANCEL, ACCEPT, ACCEPT_CANCEL, STANDBY, STANDBY_CANCEL, REJECT, REJECT_CANCEL].includes(action)
        );
  });

  const intersection = selectedActions.length
    ? selectedActions.reduce((acc, cur) => {
        return acc.filter((res) => cur.find((val) => val === res));
      })
    : [];

  const handleMutate = (action: string, ids: string[]) => {
    mutate(action, ids);
  };

  return (
    <>
      {intersection.map((action, idx) => (
        <ActionButton
          key={`${idx}-${action}`}
          action={action}
          isMany={false}
          onClick={handleMutate.bind(this, action, applicationsIds)}
          disabled={disabled || isLoading}
        />
      ))}
    </>
  );
};

export default Actions;
