import { EMPLOYEE_ROLES } from '../enums';
import { EMAIL_REGEX, NON_NUMBERS } from '../regex-patterns';
import { Translation } from 'react-i18next';

export const validateLocale = (locale) => {
  if (locale === '') {
    return <Translation>{(t) => t('errors.validation.required', { field_name: t('common.language') })}</Translation>;
  } else if (!['en', 'es'].includes(locale)) {
    return <Translation>{(t) => t('errors.validation.invalid', { field_name: t('common.language') })}</Translation>;
  }
  return '';
};

export const validateName = (name) => {
  if (name === '') {
    return <Translation>{(t) => t('errors.validation.required', { field_name: t('common.name') })}</Translation>;
  } else if (name.length < 3) {
    return (
      <Translation>
        {(t) => t('errors.validation.min_length', { field_name: t('common.name'), min_length: '3' })}
      </Translation>
    );
  } else if (name.length > 120) {
    return (
      <Translation>
        {(t) => t('errors.validation.max_length', { field_name: t('common.name'), max_length: '120' })}
      </Translation>
    );
  }
  return '';
};

export const validatePassword = (password) => {
  if (password === '') {
    return '';
  } else if (password.length < 8) {
    return (
      <Translation>
        {(t) => t('errors.validation.min_length', { field_name: t('common.password'), min_length: '8' })}
      </Translation>
    );
  } else if (password.length > 64) {
    return (
      <Translation>
        {(t) => t('errors.validation.max_length', { field_name: t('common.password'), max_length: '64' })}
      </Translation>
    );
  } else {
    return '';
  }
};

export const validateConfirm = (password, confirm) => {
  if (confirm === '' && password !== '') {
    return (
      <Translation>{(t) => t('errors.validation.required', { field_name: t('common.confirm_password') })}</Translation>
    );
  } else if (confirm !== password) {
    return <Translation>{(t) => t('errors.password.confirm')}</Translation>;
  } else {
    return '';
  }
};

export const validateNotes = (notes) => {
  if (notes.length > 255) {
    return (
      <Translation>
        {(t) => t('errors.validation.max_length', { field_name: t('common.notes'), max_length: '255' })}
      </Translation>
    );
  } else {
    return '';
  }
};

export const validatePhone = (phone) => {
  if (phone === '') {
    return <Translation>{(t) => t('errors.validation.required', { field_name: t('common.phone') })}</Translation>;
  }
  if (phone.replace(NON_NUMBERS, '').length !== 10) {
    return <Translation>{(t) => t('errors.phone.must_be_10_digits')}</Translation>;
  } else {
    return '';
  }
};

export const validateEmail = (email, list = []) => {
  if (email === '') {
    return (
      <Translation>{(t) => t('errors.validation.required', { field_name: t('common.email_address') })}</Translation>
    );
  } else if (!EMAIL_REGEX.test(email)) {
    return (
      <Translation>{(t) => t('errors.validation.invalid', { field_name: t('common.email_address') })}</Translation>
    );
  } else if (list.includes(email)) {
    return <Translation>{(t) => t('errors.email.duplicate')}</Translation>;
  } else {
    return '';
  }
};

export const validateRole = (role) => {
  if (role === '') {
    return <Translation>{(t) => t('errors.validation.required', { field_name: t('common.role') })}</Translation>;
  } else if (!Object.values(EMPLOYEE_ROLES).includes(role)) {
    return <Translation>{(t) => t('errors.validation.invalid', { field_name: t('common.role') })}</Translation>;
  } else {
    return '';
  }
};
