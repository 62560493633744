export enum ACTION_TYPES {
  PENDING = 'PENDING',
  ACCEPT = 'ACCEPT',
  ACCEPT_CANCEL = 'ACCEPT_CANCEL',
  REJECT = 'REJECT',
  REJECT_CANCEL = 'REJECT_CANCEL',
  STANDBY = 'STANDBY',
  STANDBY_CANCEL = 'STANDBY_CANCEL',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_CANCEL = 'CHECK_IN_CANCEL',
  CHECK_OUT = 'CHECK_OUT',
  CHECK_OUT_CANCEL = 'CHECK_OUT_CANCEL',
  FAILED_TO_ATTEND = 'FAILED_TO_ATTEND',
  FAILED_TO_ATTEND_CANCEL = 'FAILED_TO_ATTEND_CANCEL',
  INVITE_CANCEL = 'INVITE_CANCEL',
}

export enum BOOKING_FILTERS {
  ACCEPTED = 'ACCEPTED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
}
