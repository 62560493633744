import { gql } from 'apollo-boost';

export const SHIFT_APPLICATION_FRAGMENT = gql`
  fragment shiftApplicationFragment on ShiftApplication {
    id
    created_at
    status
    is_invite
    accepted
    accepted_by {
      id
      name
    }
    accepted_at
    rejected
    rejected_by {
      name
    }
    rejected_at
    standby
    standby_by {
      name
    }
    standby_at
    checked_in
    checked_in_at
    checked_in_by {
      id
      name
    }
    checked_out
    checked_out_at
    checked_out_by {
      id
      name
    }
    marked_missed
    marked_missed_at
    marked_missed_by {
      id
      name
    }
    shift {
      id
      accepted_shift_applications_count
      checked_in_shift_applications_count
      checked_out_shift_applications_count
      standby_shift_applications_count
      invited_shift_applications_count
      standby_shift_applications_count
    }
    dancer {
      id
      name
      first_image {
        id
        thumb
        medium
        is_verified
      }
    }
  }
`;
