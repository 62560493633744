import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import S from './invites-shift-item.module.scss';
import { PreviouslyInvited } from './invites-previously-invited';
import { BookingTimeArea } from '../../../common/booking-list/booking-list-time';
import { RadioSpan } from '../../../common/radio-buttons/radio-span';
import * as TimeHelpers from '../../../../helpers/times';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';

const AvailableShiftItem = ({
  id,
  shift_type,
  slots,
  timezone,
  end_time,
  start_time,
  isSelected,
  setSelected,
  dancerIds,
  shift_applications,
  accepted_shift_applications_count,
  checked_in_shift_applications_count,
  checked_out_shift_applications_count,
}) => {
  const { t } = useTranslation();
  const canInviteToPastBookings = posthog.isFeatureEnabled('create-past-bookings');
  const ACCEPTED_COUNT = useMemo(
    () =>
      accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count,
    [accepted_shift_applications_count, checked_in_shift_applications_count, checked_out_shift_applications_count]
  );

  const SLOTS_LEFT = slots - ACCEPTED_COUNT;

  const IS_PAST = useMemo(() => TimeHelpers.getIsNowAfterTime(end_time), [end_time]);
  const handleSelect = useCallback(
    (e) => {
      e.preventDefault();
      if (!IS_PAST || canInviteToPastBookings) setSelected(id);
    },
    [setSelected, IS_PAST, id, canInviteToPastBookings]
  );

  const PREVIOUSLY_INVITED = shift_applications.filter(({ dancer: { id } }) => dancerIds.includes(id));
  const IS_DISABLED = (IS_PAST && !canInviteToPastBookings) || PREVIOUSLY_INVITED.length === dancerIds.length;
  const StatusIndicator = () => {
    let color = SLOTS_LEFT === 0 ? 'bg-green-500' : shift_applications.length ? 'bg-blue-500' : 'bg-red-500';
    return <div className={`rounded-3xl ${color} w-2 h-2`} />;
  };

  return (
    <div className={`${S.bookingItemWrapper} flex items-center pl-2`}>
      <StatusIndicator />

      <button
        onClick={handleSelect}
        className={classNames(S.bookingItem, { [S.disabled]: IS_DISABLED })}
        disabled={IS_DISABLED}
      >
        <div className={S.type}>{t(`bookingCalendar.${shift_type}`)}</div>
        <div className={classNames(S.topArea, { [S.disabled]: IS_DISABLED })}>
          <BookingTimeArea timezone={timezone} start={start_time} end={end_time} />

          {!IS_DISABLED && <RadioSpan checked={isSelected} />}
        </div>

        <div className={S.extra}>{`${SLOTS_LEFT} ${t('modals.invite.spots_left')}`}</div>

        {PREVIOUSLY_INVITED.length > 0 && <PreviouslyInvited dancers={PREVIOUSLY_INVITED} />}
      </button>
    </div>
  );
};

AvailableShiftItem.propTypes = {
  shift_applications: PropTypes.array.isRequired,
  dancerIds: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  slots: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  accepted_shift_applications_count: PropTypes.number.isRequired,
  checked_in_shift_applications_count: PropTypes.number.isRequired,
  checked_out_shift_applications_count: PropTypes.number.isRequired,
};

export { AvailableShiftItem };
